// load sass
import '../sass/common.scss';
// modernizr // webp
// import {modernizer}  from "./common/modernizr.js";
// modernizer();
// // load imageSwitch
// import {imageSwitch} from "./common/sub_imageswtch.js";
// imageSwitch();

// WPA バージョン管理用
const appVersion = '1.0.0';

window.addEventListener('load', () => {
  let renderSyncCommon = false
  let docHeight = document.body.clientHeight - window.innerHeight - 240
  if (document.getElementsByClassName('smart-footer')[0]) {
    let pageTop = document.getElementById('js_page-top')
    let menu    = document.getElementById('js_content')
    let share   = document.getElementById('js_share')
    let footer  = document.getElementsByClassName('smart-footer')[0]
    let shareBtn= document.getElementsByClassName('share_button')[0]

    // back screen ctrl
    const expandmenu = (categories) => {
      document.getElementsByClassName('back_screen')[0].classList.add('standby')
      setTimeout(() => {
        document.getElementsByClassName('back_screen')[0].classList.add('active')
      }, 125);

      if (categories == 'share') {
        if (menu.classList.contains('active')) {
          menu.classList.remove('active')
          footer.classList.remove('active')
        }
        share.classList.add('active')
        shareBtn.classList.add('active')

      } else if (categories == 'menu') {
        menu.classList.add('active')
        footer.classList.add('active')
        share.classList.remove('active')
        shareBtn.classList.remove('active')
      }
      // shareの時、menuがactiveならmenuを一旦落とす
      // menuの時、shareがactiveならshareを一旦落とす
    }

    const contractmenu = (categories) => {
      document.getElementsByClassName('back_screen')[0].classList.remove('active')
      if (categories == 'menu') {
        footer.classList.remove('active')
      } else if (categories == 'share') {
        shareBtn.classList.add('deactive')
      }

      setTimeout(() => {
        document.getElementsByClassName('back_screen')[0].classList.remove('standby')

        if (categories == 'share') {
          share.classList.remove('active')
          setTimeout(() => {
            shareBtn.classList.remove('deactive')
            shareBtn.classList.remove('active')
          }, 600);
        } else if (categories == 'menu') {
          menu.classList.remove('active')

        }
      }, 600);
    }

    //scrolltop
    const smoothScroll = () => {
      let startY = window.pageYOffset
      const scroll = () => {
        startY = window.pageYOffset
        let y = startY - (startY - 0) / 20 - 1
        window.scrollTo(0, y)
        if (y < 0) return
        requestAnimationFrame(scroll)
      }
      requestAnimationFrame(scroll)
    }

    // tap to Menu
    menu.onclick = () => {
      menu.classList.contains('active') ? contractmenu('menu') : expandmenu('menu')
      if (share.classList.contains('active')) {
        // switch_contents('menu')
      }
    }

    // tap to Share
    share.onclick = () => {
      share.classList.contains('active') ? contractmenu('share') : expandmenu('share')
    }


    // scroll top
    pageTop.onclick = () => {
      smoothScroll()
    } // end page top.
  }


  if (window.matchMedia('(min-width: 769px)').matches) {
    // scroll effects
    let scrollEvent = () => {
      if (!renderSyncCommon) {
        requestAnimationFrame(function () {
          renderSyncCommon = false

          if (docHeight <= window.pageYOffset) {
            document.getElementById('footer').classList.add('active')
          }
        })
      }
    } // let scroll
    //END INDEX SCROLL EN¥VENTS.
    document.addEventListener('scroll', scrollEvent, {passive: true})
  } //macheMedia

})
// END SMART CONTENT MENU.
